<template>
  <Transition name="sidebar-wrapper">
    <div v-show="show" class="sidebar-wrapper">
      <Transition name="sidebar">
        <aside v-show="show" class="sidebar">
          <nav class="sidebar-nav">
            <ul class="sidebar-nav-list" @click="closeSidebar">
              <li class="sidebar-nav-list__item">
                <NuxtLink
                  :prefetch="false"
                  class="sidebar-nav__link text-18-600"
                  to="/ru-by/facilities"
                >
                  {{ $t("Facilities") }}
                </NuxtLink>
              </li>
              <li class="sidebar-nav-list__item">
                <NuxtLink
                  :prefetch="false"
                  class="sidebar-nav__link text-18-600"
                  to="/ru-by/levels"
                >
                  {{ $t("Memberships") }}
                </NuxtLink>
              </li>
              <li class="sidebar-nav-list__item">
                <NuxtLink
                  :prefetch="false"
                  class="sidebar-nav__link text-18-600"
                  to="/ru-by/companies"
                >
                  {{ $t("For Companies") }}
                </NuxtLink>
              </li>
              <li class="sidebar-nav-list__item">
                <NuxtLink
                  :prefetch="false"
                  class="sidebar-nav__link text-18-600"
                  to="/ru-by/partners"
                >
                  {{ $t("For Partners") }}
                </NuxtLink>
              </li>
              <li class="sidebar-nav-list__item">
                <NuxtLink
                  :prefetch="false"
                  class="sidebar-nav__link text-18-600"
                  to="/ru-by/contacts"
                >
                  {{ $t("Contacts") }}
                </NuxtLink>
              </li>
            </ul>
          </nav>

          <ul class="sidebar__app-links">
            <li v-for="store in filteredAppLinksBySystem" :key="store.icon">
              <a :href="store.link" target="_blank">
                <BaseIcon :icon="store.icon" />
              </a>
            </li>
          </ul>

          <div class="sidebar-controls">
            <BaseButton
              id="sidebar-offer-btn"
              class="sidebar-controls__offer"
              @click="showGetAnOfferFormModal"
            >
              {{ $t("Get an Offer") }}
            </BaseButton>
          </div>

          <LazyModalsGetAnOfferFormModal
            v-if="isShowGetAnOfferFormModal"
            v-model:is-show-modal="isShowGetAnOfferFormModal"
          />
        </aside>
      </Transition>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { DeviceSystems } from "~/types/entities";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import { useContentStore } from "~/stores/content";
import { storeToRefs } from "pinia";

const props = defineProps({
  showSidebar: {
    type: Boolean,
    default: false,
  },
  brand: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const iosRegExp = /iPad|iPhone|iPod/;
const deviceSystem = iosRegExp.test(navigator.userAgent)
  ? DeviceSystems.IOS
  : DeviceSystems.COMMON;

const contentStore = useContentStore();
const { getAppLinks: appLinks } = storeToRefs(contentStore);

const filteredAppLinksBySystem = appLinks.value?.filter((link) =>
  link?.device.includes(deviceSystem)
);

const isShowGetAnOfferFormModal = ref(false);
const show = computed(() => {
  if (props.showSidebar) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  return props.showSidebar;
});
async function closeSidebar() {
  emit("close");
}

function showGetAnOfferFormModal() {
  isShowGetAnOfferFormModal.value = true;
}
</script>
